import React from 'react';
import styled from '@emotion/styled';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Layer from '@sentry/static-shared/components/layer-next';
import { bgLight } from '@sentry/static-shared/utils/css/colors';
import metaImage from '../assets/meta/default.png';

const StyledMain = styled(Layer)`
  flex: 1;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" image={metaImage} />
    <StyledMain tag="main" backgroundColor={bgLight}>
      <h1>Ah, hell</h1>
      <p>You just hit a route that doesn&#39;t exist.</p>
    </StyledMain>
  </Layout>
);

export default NotFoundPage;
